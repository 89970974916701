// client/src/pages/SchoolInfoPage.js
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Form, Input, Button, Select, Alert, Row, Col, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  getSchoolById,
  updateSchool,
  updateSchoolLogo,
} from "../api/SchoolAPI";
import { countries, timeZones } from "../helpers/countryAndTimeZoneHelper";
import LoadingSpinner from "../components/LoadingSpinner";
import requestHandler from "../utils/requestHandler";
import "../css/SchoolInfoPage.css";

const { Option } = Select;

const SchoolInfoPage = () => {
  const [school, setSchool] = useState({});
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploading, setUploading] = useState(false);

  useEffect(()=>{
    if(uploading){
      message.success("Uploading...");
    }
  }
  ,[uploading])
  
  useEffect(() => {
    const fetchSchoolData = async () => {
      const schoolData=await requestHandler.execute(
        getSchoolById(),
        null,
        "Failed to load school data.")
      setSchool(schoolData);
      setLoading(false);
    }
    
    fetchSchoolData();
  }, []); 

  const handleFinish = async (schoolData) => {
    await requestHandler.execute(
      updateSchool(schoolData),
      "School info updated",
      "Failed to update school info")
      setErrorMessage("");
  };

  const handleUpload = async ({ file }) => {
    setUploading(true);
    const response= await requestHandler.execute(
      updateSchoolLogo(school.id, file),
      "Logo updated.",
      "Failed to upload logo.")
    setSchool((prev) => ({ ...prev, logo_url: response.logo_url }));
    setErrorMessage("")
    setUploading(false);
  };

  return (
    <div className="school-page-container">
      <Helmet>
        <title>School Info | Maidan</title>
      </Helmet>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <h1 className="page-heading">School Info</h1>
          <div className="school-logo-container">
            <img
              src={school.logo_url || "/icons/logo-icon.png"}
              alt="School Logo"
              className="school-logo"
              onClick={() => document.getElementById("logoUploadInput").click()}
            />
            <Upload
              name="logo"
              showUploadList={false}
              customRequest={handleUpload}
            >
              <Button
                icon={<UploadOutlined />}
                id="logoUploadInput"
                style={{ display: "none" }}
              >
                Upload Logo
              </Button>
            </Upload>
          </div>
          <Form
            initialValues={school}
            onFinish={handleFinish}
            layout="vertical"
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="School Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the school name.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="address_line1"
                  label="Address Line 1"
                  rules={[
                    { required: true, message: "Please input address line 1." },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="address_line2" label="Address Line 2">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="city" label="City">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="state" label="State">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="postal_code" label="Postal Code">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="country" label="Country">
                  <Select
                    showSearch
                    placeholder="Select a country"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {countries.map((country) => (
                      <Option key={country.code} value={country.name}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { type: "email", message: "Please input a valid email." },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="phone" label="Phone">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="website" label="Website">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="time_zone" label="Time Zone">
                  <Select
                    showSearch
                    placeholder="Select a time zone"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {timeZones.map((tz) => (
                      <Option key={tz} value={tz}>
                        {tz}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="date_format" label="Date Format">
                  <Select>
                    <Option value="dd/mm/yyyy">DD/MM/YYYY</Option>
                    <Option value="mm/dd/yyyy">MM/DD/YYYY</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="time_format" label="Time Format">
                  <Select>
                    <Option value="12 hour">12 Hour</Option>
                    <Option value="24 hour">24 Hour</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Update School
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            {errorMessage && (
              <Alert
                message={errorMessage}
                type="error"
                showIcon
                style={{ marginTop: 16 }}
              />
            )}
          </Form>
        </>
      )}
    </div>
  );
};

export default SchoolInfoPage;
