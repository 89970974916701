import { useState } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import { Table, Card, Row, Col } from "antd";
import "../css/AttendanceTable.css" 

const AttendanceTable = ({ attendanceData, userRole }) => {
  const [selectedStatus, setSelectedStatus] = useState(null);
  const presentData = attendanceData.filter((item) => item.present);
  const absentData = attendanceData.filter((item) => !item.present);

    const data = [
    { name: "Present", value: presentData.length },
    { name: "Absent", value: absentData.length },
  ];

  const COLORS = ["green", "red"]; 

  const columns = [
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Class", dataIndex: "classroom_name", key: "classroom_name" },
    { title: "Recorded By", dataIndex: "recorded_by", key: "recorded_by" },
    ...(userRole !== "Guardian"
      ? [
          {
            title: "Notes",
            dataIndex: "note",
            key: "note",
          },
        ]
      : []),
  ];

  const overAllColumns = [
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Class", dataIndex: "classroom_name", key: "classroom_name" }
  ];

  const handlePieClick = (data) => {
    setSelectedStatus(data.name);
  };

  return (

    <div>
        <Row gutter={[16, 16]} style={{ display: "flex"}}>
      <Col xs={24} sm={12} md={12}>
        <Card title={`OverAll Classes   -   ${attendanceData.length}`} style={{height:"400px"}}>
        <Table
                dataSource={
                    attendanceData.map((item) => ({
                        key: item.id,
                        date: item.date.slice(0, 10),
                        classroom_name: item.classroom_name
                        }))
                }
                columns={overAllColumns}
                pagination={false}
                style={{height:"100%"}}
                />
        </Card>
      </Col>

      <Col xs={24} sm={12} md={12}>
        <Card title="Attendance Overview Pie Chart" style={{height:"400px"}}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "400px" }}>
            <PieChart width={400} height={400}>
              <Pie
                data={data}
                cx="50%"
                cy="40%"
                innerRadius={60}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
                onClick={handlePieClick}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </div>
        </Card>
      </Col>
    </Row>
        {selectedStatus && (
            <Card title={`${selectedStatus} Students   -   ${presentData.length || absentData.length}`} style={{marginTop:"20px"}}>
                <Table
                dataSource={
                    selectedStatus === "Present"
                    ? presentData.map((item) => ({
                        key: item.id,
                        date: item.date.slice(0, 10),
                        classroom_name: item.classroom_name,
                        recorded_by: item.recorded_by_first_name + " " + item.recorded_by_last_name,
                        note: item.note,
                        }))
                    : absentData.map((item) => ({
                        key: item.id,
                        date: item.date.slice(0, 10),
                        classroom_name: item.classroom_name,
                        recorded_by: item.recorded_by_first_name + " " + item.recorded_by_last_name,
                        note: item.note,
                        }))
                }
                columns={columns}
                pagination={false}
                />
            </Card>
        )}
    </div>
  );
};

export default AttendanceTable;
