import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { getCategories } from "../../api/library/CategoryAPI"; // Import the API call for fetching categories

const { Option } = Select;

const CategoriesDropdown = ({ value = [], onChange }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const categoriesList = await getCategories(); // Fetch categories from the API
        setCategories(categoriesList);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
      setLoading(false);
    };

    fetchCategories();
  }, []);

  const handleSearch = (searchText) => {
    setInputValue(searchText);
  };

  const handleSelect = (selectedValue) => {
    const categoryExists = categories.some((category) => category.id === selectedValue);

    if (!categoryExists) {
      const newCategory = { id: selectedValue, name: selectedValue };
      setCategories((prevCategories) => [...prevCategories, newCategory]);
    }

    onChange([...value, selectedValue]); // Spread the current value array and add the new value
  };

  return (
    <Select
      mode="multiple" // Allow multiple selections
      value={value}
      onChange={onChange}
      onSearch={handleSearch}
      onSelect={handleSelect}
      showSearch
      placeholder="Select Categories"
      loading={loading}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
    >
      {categories.map((category) => (
        <Option key={category.name} value={category.name}>
          {category.name}
        </Option>
      ))}
      {/* Allow user to add a new option dynamically */}
      {inputValue && !categories.some((category) => category.name === inputValue) && (
        <Option key={inputValue} value={inputValue}>
          {`Add "${inputValue}"`}
        </Option>
      )}
    </Select>
  );
};

export default CategoriesDropdown;
