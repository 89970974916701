// client/src/pages/LandingPage.js
import React from "react";
import { useAuth } from "../contexts/AuthContext";
import PublicLandingPage from "./public/PublicLandingPage";
import UserRoleSelectionPage from "./UserRoleSelectionPage";
import DashboardPage from "./DashboardPage";
import MarkGuardAttendancePage from "./guards/MarkGuardAttendancePage";
import MembershipsPage from "./MembershipsPage";
import GuardianViewDevelopmentRecordPage from "./record-keeper/GuardianViewDevelopmentRecordPage";

const LandingPage = ({ setIsSidebarVisible }) => {
  const { currentUser } = useAuth();

  // Not logged in
  if (!currentUser) {
    setIsSidebarVisible(false);
    return <PublicLandingPage />;
  }

  // Check if there are any pending membership invitations
  const hasPendingInvitations = currentUser.memberships?.some(
    (membership) => membership.user_id === null
  );

  if (hasPendingInvitations) {
    return <MembershipsPage memberships={currentUser.memberships} />;
  }

  // Guards
  if (currentUser.role === "Guard") {
    return <MarkGuardAttendancePage />;
  }

  // Guardians
  if (currentUser.role === "Guardian") {
    return <GuardianViewDevelopmentRecordPage />;
  }

  // Onboarding incomplete
  if (!currentUser.currentMembershipId) {
    setIsSidebarVisible(false);
    return <UserRoleSelectionPage currentUser={currentUser} />;
  }

  // Default
  return <DashboardPage />;
};

export default LandingPage;
