import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Drawer, Button, Input } from "antd";
import CategoryEditForm from "./CategoryEditForm";
import { createCategory, updateCategory } from "../../api/library/CategoryAPI";
import requestHandler from "../../utils/requestHandler";
import { useAbility } from "../../hooks/useAbility";
import { EditOutlined } from "@ant-design/icons";
import CustomTable from "../CustomTable";

const { Search } = Input;

const CategoryList = ({
  categories,
  refreshCategories,
  showNewButton = false,
  showSearchBar = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const ability = useAbility();

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleRowClick = (record) => {
    setSelectedCategory(record);
    setIsDrawerOpen(true);
  };

  const handleNewCategory = () => {
    setSelectedCategory({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = async (categoryData) => {
    if (selectedCategory && selectedCategory.id) {
      await requestHandler.execute(
        updateCategory(selectedCategory.id, categoryData),
        "Category updated",
        "Failed to update category"
      );
    } else {
      await requestHandler.execute(
        createCategory(categoryData),
        "New category created",
        "Failed to create new category"
      );
    }
    setIsDrawerOpen(false);
    refreshCategories();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/library/categories/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => text && text.length > 101 ? `${text.slice(0, 101)}...` : text,
    },
    {
      key: "action",
      width: 50,
      render: (_, record) => (
        <Button
          icon={<EditOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            handleRowClick(record);
          }}
          disabled={!ability.can("update", "categories")}
        />
      ),
    }
  ];

  return (
    <div className="table-view">
      {showNewButton && (
        <Button
          type="default"
          onClick={handleNewCategory}
          style={{ marginBottom: 16 }}
        >
          New Category
        </Button>
      )}
      {showSearchBar && (
        <Search
          placeholder="Search by name"
          allowClear
          onChange={handleSearch}
          style={{ marginBottom: 16 }}
        />
      )}
      <CustomTable columns={columns} dataSource={filteredCategories} />
      <Drawer
        width={400}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedCategory(null);
        }}
        open={isDrawerOpen}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {isDrawerOpen && (
          <CategoryEditForm
            category={selectedCategory}
            onFinish={handleFormFinish}
            refreshCategories={refreshCategories}
            title={
              selectedCategory && selectedCategory.id
                ? "Edit Category"
                : "New Category"
            }
          />
        )}
      </Drawer>
    </div>
  );
};

export default CategoryList;
