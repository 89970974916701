import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button, Select, Typography } from "antd";
import { getPossibleParents, getPossibleSequences } from "../api/SubjectAPI";
import { formFocusHelper } from "../helpers/formFocusHelper";

const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;

const LessonEditForm = ({
  subjectId,
  lesson = {},
  onFinish,
  refreshLessons,
  title,
}) => {
  const [possibleParents, setPossibleParents] = useState([]);
  const [possibleSequences, setPossibleSequences] = useState([]);
  const [parentId, setParentId] = useState(lesson.parent_id || "");
  const ref = useRef(null);

  useEffect(() => {
    return () => formFocusHelper(ref); 
  }, []);

  useEffect(() => {
    const fetchPossibleParents = async () => {
      try {
        const parents = await getPossibleParents(subjectId, lesson.id || 0);
        setPossibleParents(parents);
      } catch (error) {
        console.error("Error fetching possible parents:", error);
      }
    };

    fetchPossibleParents();
  }, [subjectId, lesson.id]);

  useEffect(() => {
    const fetchPossibleSequences = async () => {
      try {
        const sequences = await getPossibleSequences(
          subjectId,
          parentId !== "" ? parentId : "null",
          !lesson.id // Pass true if creating a new lesson, false if updating
        );
        setPossibleSequences(sequences);
      } catch (error) {
        console.error("Error fetching possible sequences:", error);
      }
    };

    fetchPossibleSequences();
  }, [subjectId, parentId, lesson.id]);

  const handleFinish = (lessonData) => {
    const dataWithSubjectId = { ...lessonData, subject_id: subjectId };
    onFinish(dataWithSubjectId)
      .then(() => {
        refreshLessons();
      })
      .catch((error) => {
        console.error("Error handling lesson:", error);
      });
  };

  return (
    <>
      <Title level={2}>{title}</Title>
      <Form
        initialValues={lesson}
        onFinish={handleFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name="name"
          label="Lesson Name"
          rules={[{ required: true, message: "Please input the lesson name." }]}
        >
          <Input ref={ref}/>
        </Form.Item>
        <Form.Item name="parent_id" label="Parent Lesson">
          <Select onChange={setParentId} value={parentId}>
            <Option value="">None</Option>
            {possibleParents.map((parent) => (
              <Option key={parent.id} value={parent.id}>
                {parent.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="sequence" label="Sequence">
          <Select>
            {possibleSequences.map((sequence) => (
              <Option key={sequence} value={sequence}>
                {sequence}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="description" label="Description">
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {lesson.id ? "Update Lesson" : "Create Lesson"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default LessonEditForm;
