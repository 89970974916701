import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Drawer, Button, Input } from "antd";
import AuthorEditForm from "./AuthorEditForm";
import { createAuthor, updateAuthor } from "../../api/library/AuthorAPI";
import requestHandler from "../../utils/requestHandler";
import CustomTable from "../CustomTable";
import { EditOutlined } from "@ant-design/icons";
import { useAbility } from "../../hooks/useAbility";

const { Search } = Input;

const AuthorList = ({
  authors,
  refreshAuthors,
  showNewButton = false,
  showSearchBar = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const ability = useAbility();

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredAuthors = authors.filter((author) =>
    !searchQuery.includes(" ")
      ? author.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (author.last_name &&
          author.last_name.toLowerCase().includes(searchQuery.toLowerCase()))
      : author.first_name
          .toLowerCase()
          .includes(searchQuery.split(" ")[0].toLowerCase()) &&
        author.last_name &&
        author.last_name
          .toLowerCase()
          .includes(searchQuery.split(" ")[1].toLowerCase())
  );

  const handleRowClick = (record) => {
    setSelectedAuthor(record);
    setIsDrawerOpen(true);
  };

  const handleNewAuthor = () => {
    setSelectedAuthor({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = async (authorData) => {
    if (selectedAuthor && selectedAuthor.id) {
      await requestHandler.execute(
        updateAuthor(selectedAuthor.id, authorData),
        "Author updated.",
        "Failed to update author"
      );
    } else {
      await requestHandler.execute(
        createAuthor(authorData),
        "New author created.",
        "Failed to create new author"
      );
    }
    setIsDrawerOpen(false);
    refreshAuthors();
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <Link to={`/library/authors/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      render: (text, record) => (
        <Link to={`/library/authors/${record.id}`}>{text}</Link>
      ),
    },
    {
      key: "action",
      width: 50,
      render: (_, record) => (
        <Button
          icon={<EditOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            handleRowClick(record);
          }}
          disabled={!ability.can("update", "authors")}
        />
      ),
    },
  ];

  return (
    <div className="table-view">
      {showNewButton && (
        <Button
          type="default"
          onClick={handleNewAuthor}
          style={{ marginBottom: 16 }}
        >
          New Author
        </Button>
      )}
      {showSearchBar && (
        <Search
          placeholder="Search by name"
          allowClear
          onChange={handleSearch}
          style={{ marginBottom: 16 }}
        />
      )}
      <CustomTable columns={columns} dataSource={filteredAuthors} />
      <Drawer
        width={400}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedAuthor(null);
        }}
        open={isDrawerOpen}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {isDrawerOpen && (
          <AuthorEditForm
            author={selectedAuthor}
            onFinish={handleFormFinish}
            refreshAuthors={refreshAuthors}
            title={
              selectedAuthor && selectedAuthor.id ? "Edit Author" : "New Author"
            }
          />
        )}
      </Drawer>
    </div>
  );
};

export default AuthorList;
