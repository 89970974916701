import {
  getMasteryLevelsByClassroomAndSubject,
  upsertChildMastery,
} from "../api/RecordKeeperAPI";
import { getInitialSetupData } from "../api/RecordKeeperAPI";
import requestHandler from "../utils/requestHandler";

export const fetchInitialData = async (setData) => {
  try {
    const initialData = await getInitialSetupData();

    setData((prevData) => ({
      ...prevData,
      masteryLevels: initialData.masteryLevels || [],
      subjects: initialData.subjects || [],
      classrooms: initialData.classrooms || [],
      grades: initialData.grades || [],
    }));
  } catch (error) {
    console.error("Error fetching initial data:", error);
  }
};

export const fetchMasteryLevels = async (filters, setData) => {
  if (filters.selectedClassroom && filters.selectedSubject) {
    try {
      const response = await getMasteryLevelsByClassroomAndSubject(
        filters.selectedClassroom,
        filters.selectedSubject
      );

      const { lessons, children } = response;

      setData((prevData) => ({
        ...prevData,
        lessons,
        children,
      }));
    } catch (error) {
      console.error("Error fetching mastery levels:", error);
    }
  } else {
    setData((prevData) => ({
      ...prevData,
      lessons: {},
      children: {},
    }));
  }
};

export const handleCellChange = async (
  value,
  lessonId,
  childId,
  firstName,
  selectedDate,
  filters,
  setData
) => {
  const masteryLevelId = value === "" || value === "0" ? null : value;
  await requestHandler.execute(
    upsertChildMastery(childId, lessonId, masteryLevelId, selectedDate),
    `Progress updated for ${firstName}`,
    "Failed to update Progress"
  );

  if (filters.selectedClassroom && filters.selectedSubject) {
    const response = await requestHandler.execute(
      getMasteryLevelsByClassroomAndSubject(
        filters.selectedClassroom,
        filters.selectedSubject
      ),
      null,
      "Failed to refresh mastery levels"
    );

    if (response) {
      const { children } = response;
      setData((prevData) => ({
        ...prevData,
        children,
      }));
    }
  }
};

export const handleKeyDown = (
  e,
  rowIndex,
  colIndex,
  sortedLessons,
  sortedChildren,
  inputRefs,
  setFocusedCell
) => {
  let newRow = rowIndex;
  let newCol = colIndex;

  const moveCell = (row, col) => {
    e.preventDefault(); // Prevent default behavior
    const newCellRef = inputRefs.current[`cell-${row}-${col}`];
    if (newCellRef) {
      requestAnimationFrame(() => {
        newCellRef.focus();
        newCellRef.select();
      });
    }
    setFocusedCell(`cell-${row}-${col}`);
  };

  switch (e.key) {
    case "ArrowUp":
      e.preventDefault();
      newRow = rowIndex > 0 ? rowIndex - 1 : rowIndex;
      while (newRow > 0 && sortedLessons[newRow].is_root) {
        newRow--;
      }
      moveCell(newRow, newCol);
      break;
    case "ArrowDown":
      e.preventDefault();
      newRow = rowIndex < sortedLessons.length - 1 ? rowIndex + 1 : rowIndex;
      while (newRow < sortedLessons.length && sortedLessons[newRow].is_root) {
        newRow++;
      }
      moveCell(newRow, newCol);
      break;
    case "ArrowLeft":
      e.preventDefault();
      if (e.ctrlKey || e.metaKey) {
        newCol = 0;
      } else {
        newCol = colIndex > 0 ? colIndex - 1 : colIndex;
      }
      moveCell(newRow, newCol);
      break;
    case "ArrowRight":
    case "Tab":
      e.preventDefault();
      if (e.ctrlKey || e.metaKey) {
        newCol = sortedChildren.length - 1;
      } else {
        newCol = colIndex < sortedChildren.length - 1 ? colIndex + 1 : colIndex;
      }
      moveCell(newRow, newCol);
      break;
    default:
      return;
  }
};

export const sortLessons = (lessons) => {
  return lessons.sort((a, b) => {
    const aParts = a.formatted_sequence.split(".").map(Number);
    const bParts = b.formatted_sequence.split(".").map(Number);

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      if ((aParts[i] || 0) < (bParts[i] || 0)) return -1;
      if ((aParts[i] || 0) > (bParts[i] || 0)) return 1;
    }
    return 0;
  });
};

// Transform children object into array format with child_id
export const transformChildrenData = (childrenData) => {
  return Object.entries(childrenData).map(([childId, childInfo]) => ({
    ...childInfo,
    child_id: childId,
  }));
};

export const getDataSource = (filteredLessons, sortedChildren) => {
  return filteredLessons.map((lesson) => {
    const lessonData = {
      key: lesson.id,
      name: lesson.name,
      formatted_sequence: lesson.formatted_sequence,
      is_root: lesson.is_root,
      level: lesson.level,
      className: lesson.is_root ? "root-lesson-row" : "",
    };

    if (!lesson.is_root) {
      sortedChildren.forEach((child) => {
        const mastery = child.lesson_mastery.find(
          (entry) => Number(entry.lesson_id) === Number(lesson.id)
        );
        lessonData[`child_${child.child_id}`] = mastery ? mastery.level : "";
      });
    }
    return lessonData;
  });
};

export const handleInputChangeHelper = async (
  e,
  record,
  child,
  rowIndex,
  colIndex,
  data,
  temporaryDisplay,
  setTemporaryDisplay,
  selectedDate,
  filters,
  setData
) => {
  const value = e.target.value;

  // Ensure only values 0 to 5 are allowed
  if (!/^[0-5]$/.test(value)) {
    return;
  }

  const masteryLevel = data.masteryLevels.find(
    (level) => level.level.toString() === value
  );

  if (masteryLevel) {
    const temporaryText = masteryLevel.code;
    setTemporaryDisplay((prev) => ({
      ...prev,
      [`${rowIndex}-${colIndex}`]: temporaryText,
    }));

    setTimeout(() => {
      setTemporaryDisplay((prev) => ({
        ...prev,
        [`${rowIndex}-${colIndex}`]: value,
      }));
    }, 1000);
  } else {
    setTemporaryDisplay((prev) => ({
      ...prev,
      [`${rowIndex}-${colIndex}`]: value === "0" ? "" : value,
    }));
  }

  await handleCellChange(
    value,
    record.key,
    child.child_id,
    child.first_name,
    selectedDate,
    filters,
    setData
  );
};
