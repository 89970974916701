import React from "react";
import { Button, Select, Dropdown, Menu } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { createResponsiveFilterLayout } from "../helpers/responsiveFilterLayoutHelper";
import ChildSortOptions from "./ChildSortOptions";

const { Option } = Select;

const ChildListFilters = ({
  showNewButton,
  showSearchBar,
  showSortOption,
  showClassroomFilter,
  searchBar,
  sortOption,
  onSortChange,
  classrooms,
  selectedClassroom,
  onClassroomChange,
  grades,
  selectedGrade,
  onGradeChange,
  onNewChild,
  onImportChildren,
  ability,
  isMobile,
}) => {
  const menu = (
    <Menu>
      <Menu.Item key="new-child" onClick={onNewChild}>
        New Child
      </Menu.Item>
      <Menu.Item key="import-children" onClick={onImportChildren}>
        Import
      </Menu.Item>
    </Menu>
  );

  const NewButton = () => (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button type="default" icon={<PlusOutlined />} />
    </Dropdown>
  );

  const SortOptions = () => (
    <ChildSortOptions value={sortOption} onChange={onSortChange} />
  );

  const ClassroomFilter = () => (
    <Select
      placeholder="Classroom"
      style={{ width: "100%" }}
      onChange={onClassroomChange}
      value={selectedClassroom}
      allowClear
    >
      {classrooms.map(([id, name]) => (
        <Option key={id} value={id}>
          {name}
        </Option>
      ))}
    </Select>
  );

  const GradeFilter = () => (
    <Select
      placeholder="Grade"
      style={{ width: "100%" }}
      onChange={onGradeChange}
      value={selectedGrade}
      allowClear
    >
      {grades.map((grade) => (
        <Option key={grade.id} value={grade.id}>
          {grade.name}
        </Option>
      ))}
    </Select>
  );

  const filterComponents = [
    {
      component: <NewButton />,
      show: showNewButton && ability.can("create", "children"),
      desktopSpan: 1,
      mobileSpan: 3,
    },
    {
      component: searchBar,
      show: showSearchBar,
      desktopSpan: showClassroomFilter ? 11 : 17,
      mobileSpan: 21,
    },
    {
      component: <ClassroomFilter />,
      show: showClassroomFilter,
      desktopSpan: 6,
      mobileSpan: 9,
    },
    {
      component: <GradeFilter />,
      desktopSpan: 4,
      mobileSpan: 9,
    },
    {
      component: <SortOptions />,
      show: showSortOption,
      desktopSpan: 2,
      mobileSpan: 6,
    },
  ];

  const { desktopLayout, mobileLayout } =
    createResponsiveFilterLayout(filterComponents);

  return <>{isMobile ? mobileLayout : desktopLayout}</>;
};

export default ChildListFilters;
