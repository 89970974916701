// client/src/components/Sidebar.js
import React, { useState, useEffect, useRef } from "react";
import { Menu } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
import Divider from "@mui/material/Divider";
import { useAbility } from "../hooks/useAbility";
import UserInfo from "./UserInfo";
import { sidebarItems } from "../config/sidebarItems";
import { sidebarStructure } from "../config/sidebarStructure";
import { trackEvent } from "../utils/mixpanel";
import "../css/Sidebar.css";

const { SubMenu } = Menu;

const Sidebar = ({
  isMobile,
  isVisible,
  onLogout,
  onToggle,
  onMenuItemClick,
  user,
}) => {
  const sidebarRef = useRef(null);
  const ability = useAbility();
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        isVisible &&
        isMobile
      ) {
        onToggle();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, onToggle, isMobile]);

  useEffect(() => {
    const path = location.pathname;
    const splitPath = path.split("/").filter(Boolean);
    const currentKey = splitPath[splitPath.length - 1] || "dashboard";

    setSelectedKeys([currentKey]);

    const role = user?.role;
    const structure = sidebarStructure[role] || [];

    const openSubmenus = structure
      .filter(
        (item) => item.type === "submenu" && item.children.includes(currentKey)
      )
      .map((submenu) => submenu.key);

    setOpenKeys(openSubmenus);
  }, [location.pathname, user]);

  const handleMenuItemClick = (key, path) => {
    trackEvent(
      "Sidebar Item Clicked",
      {
        item: key,
      },
      user
    );

    navigate(path);

    if (isMobile) {
      onMenuItemClick();
    }
  };

  const renderMenuItem = (menuItem) => {
    const item = sidebarItems.find((i) => i.key === menuItem.key);
    if (!item) return null;

    return (
      ability.can("access", item.key) && (
        <Menu.Item
          key={item.key}
          icon={item.icon}
          onClick={() => handleMenuItemClick(item.key, item.path)}
        >
          {item.label}
        </Menu.Item>
      )
    );
  };

  const renderSubMenu = (subMenu) => {
    return (
      <SubMenu key={subMenu.key} icon={subMenu.icon} title={subMenu.label}>
        {subMenu.children.map((childKey) => {
          const childItem = sidebarItems.find((i) => i.key === childKey);
          return childItem ? renderMenuItem({ key: childKey }) : null;
        })}
      </SubMenu>
    );
  };

  const renderMenuItems = () => {
    const role = user?.role;
    const structure = sidebarStructure[role] || [];

    return structure.map((item) => {
      if (item.type === "item") {
        return renderMenuItem(item);
      } else if (item.type === "submenu") {
        return renderSubMenu(item);
      }
      return null;
    });
  };

  return (
    <div
      ref={sidebarRef}
      className={`sidebar ${isVisible ? "visible" : "hidden"}`}
    >
      <div className="sidebar-content">
        {!isMobile && (
          <div className="sidebar-header">
            <Link to="/" className="sidebar-logo">
              maidan
            </Link>
          </div>
        )}
        <Divider />
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          onOpenChange={setOpenKeys}
        >
          {renderMenuItems()}
          <Menu.Item
            key="logout"
            icon={<LogoutOutlined />}
            onClick={() => {
              onLogout();
              trackEvent("Sidebar Item Clicked", { item: "logout" }, user);
            }}
          >
            Logout
          </Menu.Item>
        </Menu>
      </div>
      {process.env.NODE_ENV === "development" && (
        <div className="user-details">
          <UserInfo user={user} />
        </div>
      )}
    </div>
  );
};

export default Sidebar;
