import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Drawer, Button, Input } from "antd";
import CollectionEditForm from "./CollectionEditForm";
import {
  createCollection,
  updateCollection,
} from "../../api/library/CollectionAPI";
import requestHandler from "../../utils/requestHandler";
import { useAbility } from "../../hooks/useAbility";
import { EditOutlined } from "@ant-design/icons";
import CustomTable from "../CustomTable";

const { Search } = Input;

const CollectionList = ({
  collections,
  refreshCollections,
  showNewButton = false,
  showSearchBar = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const ability = useAbility();

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredCollections = collections.filter((collection) =>
    collection.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleRowClick = (record) => {
    setSelectedCollection(record);
    setIsDrawerOpen(true);
  };

  const handleNewCollection = () => {
    setSelectedCollection({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = async (collectionData) => {
    if (selectedCollection && selectedCollection.id) {
      await requestHandler.execute(
        updateCollection(selectedCollection.id, collectionData),
        "Collection updated.",
        "Failed to update collection"
      );
    } else {
      await requestHandler.execute(
        createCollection(collectionData),
        "New collection created.",
        "Failed to create new collection"
      );
    }
    setIsDrawerOpen(false);
    refreshCollections();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/library/collections/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => text && text.length > 101 ? `${text.slice(0, 101)}...` : text,
    },
    {
      key: "action",
      width: 50,
      render: (_, record) => (
        <Button
          icon={<EditOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            handleRowClick(record);
          }}
          disabled={!ability.can("update", "collections")}
        />
      ),
    }
  ];

  return (
    <div className="table-view">
      {showNewButton && (
        <Button
          type="default"
          onClick={handleNewCollection}
          style={{ marginBottom: 16 }}
        >
          New Collection
        </Button>
      )}
      {showSearchBar && (
        <Search
          placeholder="Search by name"
          allowClear
          onChange={handleSearch}
          style={{ marginBottom: 16 }}
        />
      )}
      <CustomTable columns={columns} dataSource={filteredCollections} />
      <Drawer
        width={400}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedCollection(null);
        }}
        open={isDrawerOpen}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {isDrawerOpen && (
          <CollectionEditForm
            collection={selectedCollection}
            onFinish={handleFormFinish}
            refreshCollections={refreshCollections}
            title={
              selectedCollection && selectedCollection.id
                ? "Edit Collection"
                : "New Collection"
            }
          />
        )}
      </Drawer>
    </div>
  );
};

export default CollectionList;
