import React, { useCallback, useEffect, useState } from "react";
import { Select, Table, message, Popover } from "antd";
import { createResponsiveFilterLayout } from "../../helpers/responsiveFilterLayoutHelper";
import {
  fetchInitialData,
  sortLessons,
} from "../../helpers/recordKeeperHelper";
import { getProgressOverviewByClassroomAndSubject } from "../../api/RecordKeeperAPI";
import LoadingSpinner from "../../components/LoadingSpinner";
import "../../css/record-keeper/RecordKeeperPage.css";

const { Option } = Select;

const ProgressOverviewPage = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    masteryLevels: [],
    subjects: [],
    classrooms: [],
    grades: [],
  });
  const [selectedClassroom, setSelectedClassroom] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [classroomGrades, setClassroomGrades] = useState([]);
  const [masteryData, setMasteryData] = useState([]);
  const [visiblePopover, setVisiblePopover] = useState(null);

  // Fetch initial data for filters
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchInitialData(setData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching initial data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleFilterChange = useCallback(async () => {
    if (!selectedClassroom || !selectedSubject) return;

    setLoading(true);
    try {
      const response = await getProgressOverviewByClassroomAndSubject(
        selectedClassroom,
        selectedSubject,
        selectedGrade
      );
      setMasteryData(sortLessons(response.mastery_overview)); // Sort lessons using sortLessons
    } catch (error) {
      console.error("Error fetching progress overview data:", error);
      message.error("Failed to load progress overview data.");
    } finally {
      setLoading(false);
    }
  },[selectedClassroom, selectedGrade, selectedSubject])

  // Update the Grade filter options when a Classroom is selected
  useEffect(() => {
    const selectedClassroomData = data.classrooms.find(
      (classroom) => classroom.id === selectedClassroom
    );

    if (selectedClassroomData && selectedClassroomData.grades) {
      setClassroomGrades(selectedClassroomData.grades);
    } else {
      setClassroomGrades([]);
    }
  }, [selectedClassroom, data.classrooms]);

  // Trigger API call when Subject or Grade changes
  useEffect(() => {
    handleFilterChange();
  }, [selectedClassroom, selectedSubject, selectedGrade, handleFilterChange]);

  const ClassroomFilter = () => (
    <Select
      placeholder="Select Classroom"
      style={{ width: "100%" }}
      onChange={(value) => {
        setSelectedClassroom(value);
        setSelectedGrade([]); // Reset Grade filter when Classroom changes
      }}
      value={selectedClassroom}
      allowClear
    >
      {data.classrooms.map((classroom) => (
        <Option key={classroom.id} value={classroom.id}>
          {classroom.name}
        </Option>
      ))}
    </Select>
  );

  const GradeFilter = () => (
    <Select
      mode="multiple"
      placeholder="Select Grade"
      style={{ width: "100%" }}
      onChange={(value) => setSelectedGrade(value)}
      value={selectedGrade}
      allowClear
    >
      {classroomGrades.map((grade) => (
        <Option key={grade.id} value={grade.id}>
          {grade.name}
        </Option>
      ))}
    </Select>
  );

  const SubjectFilter = () => (
    <Select
      placeholder="Select Subject"
      style={{ width: "100%" }}
      onChange={(value) => setSelectedSubject(value)}
      value={selectedSubject}
      allowClear
    >
      {data.subjects.map((subject) => (
        <Option key={subject.id} value={subject.id}>
          {subject.name}
        </Option>
      ))}
    </Select>
  );

  const filterComponents = [
    { component: <ClassroomFilter />, desktopSpan: 4, mobileSpan: 8 },
    {
      component: <GradeFilter />,
      desktopSpan: 4,
      mobileSpan: 8,
    },
    { component: <SubjectFilter />, desktopSpan: 4, mobileSpan: 8 },
  ];

  const { desktopLayout } =
    createResponsiveFilterLayout(filterComponents);

  // Define the table columns dynamically based on mastery levels
  const dynamicColumns = data.masteryLevels.map((level) => ({
    title: level.name,
    dataIndex: `level_${level.id}`,
    key: `level_${level.id}`,
    render: (text, record) => {
      if (record.parent_id === null) return null;

      const percentage = text?.percentage ?? 0;
      const count = text?.count ?? 0;

      const popoverContent =
        text?.children && text.children.length > 0 ? (
          <div>
            <ul style={{ paddingLeft: "20px" }}>
              {text.children.map((child) => (
                <li key={child.child_id}>{child.child_name}</li>
              ))}
            </ul>
          </div>
        ) : (
          "No children"
        );

      return (
        <Popover
          content={popoverContent}
          trigger="click"
          visible={visiblePopover === `${record.lesson_id}_${level.id}`}
          onVisibleChange={(visible) =>
            setVisiblePopover(
              visible ? `${record.lesson_id}_${level.id}` : null
            )
          }
        >
          <div style={{ cursor: "pointer" }}>
            {percentage}% <br />
            <small>
              ({count} {count === 1 ? "child" : "children"})
            </small>
          </div>
        </Popover>
      );
    },
  }));

  // Add a "Not Started" column after the "Lesson Name"
  const columns = [
    {
      title: "Lesson Name",
      dataIndex: "lesson_name",
      key: "lesson_name",
      fixed: "left",
      render: (text, record) => (
        <div
          className={`${record.parent_id ? "non-root-lesson" : "root-lesson"}`}
        >
          {record.formatted_sequence} {text}
        </div>
      ),
    },
    {
      title: "Not Started",
      dataIndex: "not_started",
      key: "not_started",
      render: (text, record) => {
        if (record.parent_id === null) return null;

        const count = text?.count ?? 0;
        const percentage = text?.percentage ?? 0;
        const popoverContent =
          text?.children && text.children.length > 0 ? (
            <div>
              <ul style={{ paddingLeft: "20px" }}>
                {text.children.map((child) => (
                  <li key={child.child_id}>{child.child_name}</li>
                ))}
              </ul>
            </div>
          ) : (
            "No children"
          );

        return (
          <Popover
            content={popoverContent}
            trigger="click"
            visible={visiblePopover === `${record.lesson_id}_not_started`}
            onVisibleChange={(visible) =>
              setVisiblePopover(
                visible ? `${record.lesson_id}_not_started` : null
              )
            }
          >
            <div style={{ cursor: "pointer" }}>
              {percentage}% <br />
              <small>
                ({count} {count === 1 ? "child" : "children"})
              </small>
            </div>
          </Popover>
        );
      },
    },
    ...dynamicColumns,
  ];

  return (
    <div className="mastery-overview-page">
      <h1 className="page-title">Progress Overview</h1>
      <div className="filters">{desktopLayout}</div>
      <div className="table-container">
        {loading ? (
          <LoadingSpinner tip="Loading data..." />
        ) : (
          <Table
            columns={columns}
            dataSource={masteryData}
            rowKey={(record) => record.lesson_id}
            pagination={false}
            bordered
            scroll={{ x: "max-content", y: 560 }}
          />
        )}
      </div>
    </div>
  );
};

export default ProgressOverviewPage;
