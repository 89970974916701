import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Table, Button } from "antd";
import LoadingSpinner from "../components/LoadingSpinner";
import { acceptInvitation, rejectInvitation } from "../api/MembershipsAPI";
import requestHandler from "../utils/requestHandler";

const MembershipsPage = ({ memberships }) => {
  const [invitations, setInvitations] = useState(
    memberships.filter((membership) => membership.user_id === null)
  );
  const [loading, setLoading] = useState(false);

  const handleAccept = async (id) => {
    setLoading(true);
    await requestHandler.execute(
      acceptInvitation(id),
      "Invitation accepted.",
      "Failed to accept invitation.",
      () => {
        setInvitations((prevInvitations) =>
          prevInvitations.filter((invitation) => invitation.id !== id)
        );
      }
    );
    setLoading(false);
  };

  const handleReject = async (id) => {
    setLoading(true);
    await requestHandler.execute(
      rejectInvitation(id),
      "Invitation rejected.",
      "Failed to reject invitation.",
      () => {
        setInvitations((prevInvitations) =>
          prevInvitations.filter((invitation) => invitation.id !== id)
        );
      }
    );
    setLoading(false);
  };

  const columns = [
    {
      title: "School Name",
      dataIndex: "school_name",
      key: "school_name",
    },
    {
      title: "Invitation Sent On",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button type="primary" onClick={() => handleAccept(record.id)}>
            Accept
          </Button>
          <Button
            type="danger"
            onClick={() => handleReject(record.id)}
            style={{ marginLeft: 10 }}
          >
            Reject
          </Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <Helmet>
        <title>My Memberships | Maidan</title>
      </Helmet>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <h1 className="page-heading">My Memberships</h1>
          <Table
            columns={columns}
            dataSource={invitations}
            rowKey="id"
            pagination={false}
          />
        </>
      )}
    </div>
  );
};

export default MembershipsPage;
