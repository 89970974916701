import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Select } from "antd";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useAuth } from "../../contexts/AuthContext";
import {
  renderMasteryContent,
  fetchMasteryLevels,
  renderAttendanceContent,
} from "../../helpers/developmentRecordHelper";
import requestHandler from "../../utils/requestHandler";
import "../../css/record-keeper/RecordKeeperPage.css";

const { Option } = Select;

const GuardianViewDevelopmentRecordPage = ({ isMobile }) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState({
    subjects: [],
    masteryLevels: {},
    children: [],
    attendance: [],
  });
  const [filters, setFilters] = useState({
    selectedChild: "",
    selectedSubject: "",
  });

  useEffect(() => {
    if (currentUser.children && currentUser.children.length > 0) {
      const transformedChildren = currentUser.children.map((child) => ({
        id: child.child_id,
        sid: child.sid,
        first_name: child.first_name,
        last_name: child.last_name,
        date_of_birth: child.date_of_birth,
        profile_photo_url: child.profile_photo_url || "",
        classrooms: child.classrooms,
        grade: child.grade,
      }));

      setData((prevData) => ({
        ...prevData,
        children: transformedChildren,
      }));

      setFilters((prevFilters) => ({
        ...prevFilters,
        selectedChild: transformedChildren[0].id,
      }));
    }
    setLoading(false);
  }, [currentUser.children]);

  useEffect(() => {
    if (filters.selectedChild) {
      requestHandler.execute(
        () => fetchMasteryLevels(filters.selectedChild, setData),
        null,
        "Failed to fetch mastery levels.",
        null,
        (error) => {
          setErrorMessage(error.message || "Failed to fetch mastery levels");
        }
      );
    }
  }, [filters.selectedChild]);

  const handleChange = (field) => (value) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <div className="development-record-page">
      <Helmet>
        <title>Development Record | Maidan</title>
      </Helmet>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <h1 className="page-heading">Development Record</h1>

          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

          {data.children.length === 2 && (
            <Select
              style={{ width: 200, marginBottom: 16, marginRight: 16 }}
              value={filters.selectedChild}
              onChange={handleChange("selectedChild")}
              placeholder="Select Child"
            >
              {data.children.map((child) => (
                <Option key={child.id} value={child.id}>
                  {child.first_name} {child.last_name}
                </Option>
              ))}
            </Select>
          )}

          <Select
            style={{ width: 200, marginBottom: 16 }}
            value={filters.selectedSubject}
            onChange={handleChange("selectedSubject")}
            allowClear
            placeholder="Select Subject"
          >
            {data.subjects.map((subject) => (
              <Option key={subject.id} value={subject.id}>
                {subject.name}
              </Option>
            ))}
          </Select>

          <div>
            {renderMasteryContent(
              data.masteryLevels,
              filters.selectedSubject,
              isMobile,
              "Guardian"
            )}

            {data.attendance.length > 0 && (
              <>
                <h2>Attendance Log</h2>
                <div>
                  {renderAttendanceContent(data.attendance, "Guardian")}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default GuardianViewDevelopmentRecordPage;
