import React from "react";
import { Select } from "antd";
import SelectInput from "../SelectInput";
import { createResponsiveFilterLayout } from "../../helpers/responsiveFilterLayoutHelper";

const { Option } = Select;

const DevelopmentRecordFilters = ({
  filters,
  handleChange,
  data,
  isMobile,
  showClassroomFilter,
  showSubjectFilter,
  showChildrenFilter,
}) => {
  const ClassroomFilter = () => (
    <SelectInput
      placeholder="Classroom"
      value={filters.selectedClassroom}
      onChange={handleChange("selectedClassroom")}
      options={data.classrooms}
    />
  );

  const SubjectFilter = () => (
    <Select
      placeholder="Subject"
      value={filters.selectedSubject || null}
      onChange={handleChange("selectedSubject")}
      allowClear
      style={{ width: "100%" }}
    >
      {data.subjects.length > 0 ? (
        data.subjects.map((subject) => (
          <Option key={subject.id} value={subject.id}>
            {subject.name}
          </Option>
        ))
      ) : (
        <Option disabled></Option>
      )}
    </Select>
  );

  const ChildrenFilter = () => {
    const filteredChildren = filters.selectedClassroom
      ? data.children.filter(
          (child) => child.classroom_id === filters.selectedClassroom
        )
      : data.children;

    return (
      <Select
        showSearch
        placeholder="Children"
        value={filters.selectedChildren || undefined}
        onChange={handleChange("selectedChildren")}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
        style={{ width: "100%" }}
        allowClear
      >
        {filteredChildren.length > 0 ? (
          filteredChildren.map((child) => (
            <Option key={child.child_id} value={child.child_id}>
              {child.first_name} {child.last_name}
            </Option>
          ))
        ) : (
          <Option disabled>No Children Available</Option>
        )}
      </Select>
    );
  };

  const filterComponents = [
    {
      component: <ClassroomFilter />,
      show: showClassroomFilter,
      desktopSpan: 6,
      mobileSpan: 24,
    },
    {
      component: <ChildrenFilter />,
      show: showChildrenFilter,
      desktopSpan: 6,
      mobileSpan: 12,
    },
    {
      component: <SubjectFilter />,
      show: showSubjectFilter,
      desktopSpan: 6,
      mobileSpan: 12,
    },
  ];

  const { desktopLayout, mobileLayout } =
    createResponsiveFilterLayout(filterComponents);

  return <>{isMobile ? mobileLayout : desktopLayout}</>;
};

export default DevelopmentRecordFilters;
