import React, { useState, useEffect, useRef, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import { Input, Table, Button, Row, Col } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import RecordKeeperFilters from "../../components/record-keeper/RecordKeeperFilters";
import MobileMasterySelector from "../../components/record-keeper/MobileMasterySelector";
import LoadingSpinner from "../../components/LoadingSpinner";
import IncompleteSetupMessage from "../../components/IncompleteSetupMessage";
import MasteryNotesModal from "../../components/record-keeper/MasteryNotesModal";
import "../../css/record-keeper/RecordKeeperPage.css";
import { useAuth } from "../../contexts/AuthContext";
import { getIncompleteSetupMessage } from "../../helpers/setupHelper";
import useScrollTableToTop from "../../hooks/useScrollTableToTop";
import {
  fetchInitialData,
  fetchMasteryLevels,
  handleCellChange,
  handleKeyDown,
  sortLessons,
  transformChildrenData,
  getDataSource,
  handleInputChangeHelper,
} from "../../helpers/recordKeeperHelper";
import { getSortedChildren } from "../../helpers/childHelper";
import { calculateAge } from "../../helpers/dateTimeHelper";
import { shouldShowClassroomSelector } from "../../helpers/teacherHelper";
import { trackEvent } from "../../utils/mixpanel";

const RecordKeeperPage = ({ isMobile, setIsSidebarVisible }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    masteryLevels: [],
    subjects: [],
    classrooms: [],
    grades: [],
    lessons: {},
    children: {},
  });
  const [filters, setFilters] = useState({
    selectedSubject: "",
    selectedClassroom: "",
    selectedGrade: "",
    selectedLessons: [],
    selectedChildren: [],
    sortOption: "ageLowToHigh",
    selectedLevels: [],
    shouldShowClassroomSelector: shouldShowClassroomSelector(currentUser),
  });
  const [selectedDate, setSelectedDate] = useState("");
  const [focusedCell, setFocusedCell] = useState(null);
  const [temporaryDisplay, setTemporaryDisplay] = useState({});
  const [selectedMastery, setSelectedMastery] = useState(null);
  const [isNotesModalVisible, setIsNotesModalVisible] = useState(false);
  const [selectedChild, setSelectedChild] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);

  const handleOpenNotesModal = (child, lesson) => {
    setSelectedChild(child);
    setSelectedLesson(lesson);
    setIsNotesModalVisible(true);
  };

  const inputRefs = useRef({});

  const setInputRef = useCallback((element, rowIndex, colIndex) => {
    if (element) {
      inputRefs.current[`cell-${rowIndex}-${colIndex}`] = element;
    }
  }, []);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
  };

  useEffect(() => {
    setSelectedDate(moment().format("YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setIsSidebarVisible(false);
      return () => setIsSidebarVisible(true);
    }
  }, [isMobile, setIsSidebarVisible]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchInitialData(setData);
        setLoading(false);
        trackEvent("RecordKeeperPage Loaded", {}, currentUser);
      } catch (error) {
        console.error("Error fetching initial data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [currentUser]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await fetchMasteryLevels(filters, setData);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    filters.selectedClassroom,
    filters.selectedSubject,
    filters.selectedGrade,
    filters,
  ]);

  useEffect(() => {
    if (currentUser.classrooms?.length > 0 && !filters.selectedClassroom) {
      setFilters((prev) => ({
        ...prev,
        selectedClassroom: currentUser.classrooms[0].id,
      }));
    }
  }, [currentUser.classrooms, filters.selectedClassroom]);

  useScrollTableToTop([
    filters.selectedSubject,
    filters.selectedClassroom,
    filters.selectedGrade,
    filters.sortOption,
  ]);

  const handleChange = (field) => (value) => {
    setFilters((prev) => {
      let newFilters = {
        ...prev,
        [field]: value !== undefined && value !== null ? value : [],
      };

      // Helper function to reset all filters
      const resetAllFilters = () => {
        newFilters.selectedSubject = "";
        newFilters.selectedLessons = [];
        newFilters.selectedGrade = "";
        newFilters.selectedLevels = [];
        newFilters.selectedChildren = [];
      };

      // Clear mastery level cell values and reset data for relevant changes
      if (
        [
          "selectedClassroom",
          "selectedSubject",
          "selectedLessons",
          "selectedGrade",
          "selectedChildren",
        ].includes(field)
      ) {
        setTemporaryDisplay({});
      }

      // Reset data only for major filter changes
      if (
        [
          "selectedClassroom",
          "selectedSubject",
          "selectedLessons",
          "selectedGrade",
        ].includes(field)
      ) {
        setData((prevData) => ({
          ...prevData,
          lessons: {},
          children: {},
        }));
      }

      switch (field) {
        case "selectedClassroom":
          resetAllFilters();
          break;
        case "selectedSubject":
          newFilters.selectedLessons = [];
          newFilters.selectedGrade = "";
          newFilters.selectedLevels = [];
          newFilters.selectedChildren = [];
          break;
        case "selectedGrade":
          newFilters.selectedChildren = [];
          break;
        case "selectedLessons":
        case "selectedLevels":
        case "selectedChildren":
          // These don't reset other filters, but temporary display is cleared
          break;
        default:
          break;
      }

      return newFilters;
    });

    trackEvent(
      "RecordKeeperPage Filter Changed",
      {
        field,
        value,
      },
      currentUser
    );
  };

  const sortedLessons = sortLessons(Object.values(data.lessons));
  const filteredLessons =
    filters.selectedLessons.length > 0
      ? sortedLessons.filter((lesson) => {
          return (
            filters.selectedLessons === String(lesson.id) ||
            filters.selectedLessons === String(lesson.parent_id)
          );
        })
      : sortedLessons;

  const transformedChildren = transformChildrenData(data.children);

  const gradeFilteredChildren = filters.selectedGrade
    ? transformedChildren.filter(
        (child) => child.grade_id === filters.selectedGrade
      )
    : transformedChildren;

  const filteredChildren =
    filters.selectedChildren.length > 0
      ? gradeFilteredChildren.filter((child) =>
          filters.selectedChildren.includes(child.first_name)
        )
      : gradeFilteredChildren;

  const sortedChildren = getSortedChildren(
    filteredChildren,
    filters.sortOption
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".ant-input")) {
        setFocusedCell(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e, record, child, rowIndex, colIndex) => {
    handleInputChangeHelper(
      e,
      record,
      child,
      rowIndex,
      colIndex,
      data,
      temporaryDisplay,
      setTemporaryDisplay,
      selectedDate,
      filters,
      setData
    );
  };
  const columns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 250,
      className: "left-align",
      render: (text, record) => (
        <div
          className={`${record.is_root ? "root-lesson" : "non-root-lesson"}`}
        >
          {record.formatted_sequence} {text}
        </div>
      ),
    },
    ...sortedChildren.map((child, colIndex) => {
      const childDOB = child.date_of_birth;
      const age = calculateAge(childDOB);
      return {
        title: (
          <>
            <div className="age">{age ? `${age}` : "NA"}</div>
            <Link to={`/children/${child.child_id}`} className="child-link">
              {child.first_name}
            </Link>
          </>
        ),
        dataIndex: `child_${child.child_id}`,
        key: `child_${child.child_id}`,
        className: "center-align top-align",
        render: (text, record, rowIndex) => {
          if (record.is_root) {
            return {
              children: <div className="root-lesson-cell">{text}</div>,
              props: {
                colSpan: 1,
                className: "root-lesson-cell",
              },
            };
          }

          const mastery = child.lesson_mastery.find(
            (entry) => Number(entry.lesson_id) === Number(record.key)
          );

          const color = mastery
            ? data.masteryLevels.find((level) => level.level === mastery.level)
                ?.color
            : "#FFFFFF";

          const isFocused = focusedCell === `cell-${rowIndex}-${colIndex}`;

          const shouldRenderCell =
            filters.selectedLevels.length === 0 ||
            (mastery && filters.selectedLevels.includes(mastery.level));

          return shouldRenderCell ? (
            <div className="input-container">
              <Input
                type="text"
                inputMode="numeric"
                value={
                  temporaryDisplay[`${rowIndex}-${colIndex}`] || text || ""
                }
                onChange={(e) =>
                  handleInputChange(e, record, child, rowIndex, colIndex)
                }
                className={`${isFocused ? "highlight-border" : ""}`}
                style={{
                  backgroundColor: color,
                }}
                ref={(el) => setInputRef(el, rowIndex, colIndex)}
                onKeyDown={(e) =>
                  handleKeyDown(
                    e,
                    rowIndex,
                    colIndex,
                    filteredLessons,
                    sortedChildren,
                    inputRefs,
                    setFocusedCell
                  )
                }
                onClick={(e) => {
                  if (!isMobile) {
                    e.target.select();
                  }
                  setFocusedCell(`cell-${rowIndex}-${colIndex}`);
                  if (isMobile) {
                    setSelectedMastery({ record, child, rowIndex, colIndex });
                  }
                }}
                readOnly={isMobile}
              />

              <Button
                className="notes-button"
                onClick={() => {
                  handleOpenNotesModal(child, record);
                }}
              >
                +
              </Button>
            </div>
          ) : null;
        },
      };
    }),
  ];
  const dataSource = getDataSource(filteredLessons, sortedChildren);

  const incompleteMessage = getIncompleteSetupMessage(currentUser);
  if (incompleteMessage) {
    setIsSidebarVisible(true);
    return (
      <IncompleteSetupMessage
        title="Development Record"
        message={incompleteMessage}
      />
    );
  }

  return (
    <div className="record-keeper-page">
      <Helmet>
        <title>Record Keeper | Maidan</title>
      </Helmet>
      {loading ? (
        <LoadingSpinner />
      ) : incompleteMessage ? (
        <IncompleteSetupMessage
          title="Development Record"
          message={incompleteMessage}
        />
      ) : (
        <>
          {isMobile && selectedMastery && (
            <MobileMasterySelector
              selectedMastery={selectedMastery}
              data={data}
              masteryLevels={data.masteryLevels.map((level) => level.level)}
              handleInputChange={async (
                level,
                record,
                child,
                rowIndex,
                colIndex
              ) => {
                await handleCellChange(
                  level,
                  record.key,
                  child.child_id,
                  child.first_name,
                  selectedDate,
                  filters,
                  setData
                );
                setSelectedMastery(null);
              }}
              setSelectedMastery={setSelectedMastery}
              handleOpenNotesModal={handleOpenNotesModal}
            />
          )}

          <Row
            align="middle"
            justify="space-between"
            gutter={8}
            style={{ marginBottom: 16 }}
          >
            <Col>
              <Row align="middle" gutter={8}>
                {!isMobile && (
                  <Col>
                    <CloseOutlined
                      className="close-icon"
                      onClick={() => navigate("/")}
                    />
                  </Col>
                )}
                <Col>
                  <h1 className="page-heading-without-bottom-margin">
                    Record Keeper
                  </h1>
                </Col>
              </Row>
            </Col>
            <Col>
              <input
                type="date"
                value={selectedDate}
                onChange={handleDateChange}
                className="custom-date-picker"
                max={moment().format("YYYY-MM-DD")}
              />
            </Col>
          </Row>

          <RecordKeeperFilters
            filters={filters}
            handleChange={handleChange}
            data={data}
            isMobile={isMobile}
          />

          {sortedChildren.length > 0 && filteredLessons.length > 0 && (
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              bordered
              scroll={{ x: "max-content", y: 560 }}
              sticky={{ offsetHeader: 64 }}
            />
          )}

          {selectedChild && selectedLesson && (
            <MasteryNotesModal
              visible={isNotesModalVisible}
              onClose={() => setIsNotesModalVisible(false)}
              child={selectedChild}
              lesson={selectedLesson}
            />
          )}
        </>
      )}
    </div>
  );
};

export default RecordKeeperPage;
