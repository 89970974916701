import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import ChildList from "../components/ChildList";
import ManageChildrenGradeModal from "../components/ManageChildrenGradeModal";
import LoadingSpinner from "../components/LoadingSpinner";
import {
  getGradeById,
  mapChildToGrade,
  unmapChildFromGrade,
} from "../api/GradeAPI";
import { useAbility } from "../hooks/useAbility";

const GradePage = () => {
  const { gradeId } = useParams();
  const [grade, setGrade] = useState(null);
  const [isChildModalVisible, setIsChildModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const ability = useAbility();

  const fetchGrade = useCallback(async () => {
    try {
      const data = await getGradeById(gradeId);
      setGrade(data);
    } catch (error) {
      console.error("Error fetching grade:", error);
    }
  }, [gradeId]);

  useEffect(() => {
    fetchGrade();
  }, [fetchGrade]);

  const handleAddChildren = async (childIds) => {
    try {
      for (const childId of childIds) {
        await mapChildToGrade(gradeId, childId);
      }
      setIsChildModalVisible(false);
      fetchGrade();
    } catch (error) {
      console.error("Error adding children to grade:", error);
    }
  };

  const handleRemoveChildren = async (childIds) => {
    try {
      for (const childId of childIds) {
        await unmapChildFromGrade(gradeId, childId);
      }
      setIsChildModalVisible(false);
      fetchGrade();
    } catch (error) {
      console.error("Error removing children from grade:", error);
    }
  };

  return (
    <div>
      <Helmet>
        <title>{grade ? `${grade.name} | Maidan` : "Loading..."}</title>
      </Helmet>
      {!grade ? (
        <LoadingSpinner />
      ) : (
        <>
          <h1 className="page-heading">{grade.name}</h1>
          <h3>Children</h3>
          {ability.can("add", "children") && (
            <Button
              onClick={() => {
                setModalMode("add");
                setIsChildModalVisible(true);
              }}
            >
              Add Children
            </Button>
          )}
          {ability.can("remove", "children") && (
            <Button
              onClick={() => {
                setModalMode("remove");
                setIsChildModalVisible(true);
              }}
              style={{ marginLeft: 8 }}
            >
              Remove Children
            </Button>
          )}
          <ChildList
            children={grade.children}
            showSortOption={true}
            showSearchBar={true}
          />
          <ManageChildrenGradeModal
            gradeId={gradeId}
            isOpen={isChildModalVisible}
            onClose={() => setIsChildModalVisible(false)}
            onAdd={
              modalMode === "add" ? handleAddChildren : handleRemoveChildren
            }
            mode={modalMode}
            existingChildren={grade.children}
          />
        </>
      )}
    </div>
  );
};

export default GradePage;
