import axios from "axios";

const baseURL = "/memberships";

const api = axios.create({
  baseURL,
});

export const createEmployee = async (employeeData) => {
  try {
    const response = await api.post("/employees", employeeData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("An unexpected error occurred. Please try again.");
    }
  }
};

export const getEmployees = async () => {
  try {
    const response = await api.get("/employees");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEmployeeById = async (employeeId = "") => {
  try {
    const response = await api.get(`/employees/${employeeId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateEmployee = async (employeeId, employeeData) => {
  try {
    const response = await api.put(`/employees/${employeeId}`, employeeData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPresentations = async (membershipId, classroomId = null) => {
  console.log("getPresentations", membershipId);
  try {
    const response = await api.post(`/presentations`, {
      membershipId,
      classroomId,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching presentations:", error);
    throw error;
  }
};
