import React, { useEffect } from "react";
import { Button, Row } from "antd";
import "../../css/record-keeper/MobileMasterySelector.css";

const MobileMasterySelector = ({
  selectedMastery,
  data,
  masteryLevels,
  handleInputChange,
  setSelectedMastery,
  handleOpenNotesModal,
}) => {
  useEffect(() => {
    // Hide the ribbon when clicking outside
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".mastery-level-ribbon") &&
        !event.target.closest(".ant-input")
      ) {
        setSelectedMastery(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setSelectedMastery]);

  const { record, child, rowIndex, colIndex } = selectedMastery;
  const mastery = data.children[child.child_id]?.lesson_mastery.find(
    (entry) => entry.lesson_id === record.key
  );

  const lessonName = record.name;

  return (
    <div className="mastery-level-ribbon">
      <div className="mastery-info">
        {child.first_name} - {lessonName}
      </div>

      <Row justify="center" gutter={16}>
        {/* Add a button for value 0 */}
        <Button
          key={0}
          type={mastery?.level === 0 ? "primary" : "default"}
          onClick={() => {
            handleInputChange(null, record, child, rowIndex, colIndex);
          }}
          className="mastery-level-button"
        >
          0
        </Button>

        {masteryLevels.map((level) => (
          <Button
            key={level}
            type={mastery?.level === level ? "primary" : "default"}
            onClick={() => {
              handleInputChange(level, record, child, rowIndex, colIndex); // Update mastery level on button click
            }}
            className="mastery-level-button"
          >
            {level}
          </Button>
        ))}

        <Button
          type="default"
          onClick={() => handleOpenNotesModal(child, record)}
          className="mobile-notes-button"
        >
          Notes
        </Button>
      </Row>
    </div>
  );
};

export default MobileMasterySelector;
