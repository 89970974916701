// client/src/components/MobileHeader.js
import React from "react";
import { MenuOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "../css/MobileHeader.css";
import useInstallPrompt from "../hooks/useInstallPrompt";

const MobileHeader = ({ onToggleSidebar }) => {
  const { isInstallable, handleInstallClick } = useInstallPrompt();

  return (
    <div className="mobile-header">
      <MenuOutlined className="hamburger-icon" onClick={onToggleSidebar} />
      <Link to="/" className="logo-link">
        <div className="logo">maidan</div>
      </Link>
      {isInstallable && (
        <button className="install-button" onClick={handleInstallClick}>
          Get App
        </button>
      )}
      {/* <Link to="/copilot" className="copilot-icon-link">
        <ThunderboltOutlined className="copilot-icon" />
      </Link> */}
    </div>
  );
};

export default MobileHeader;
