import { Table, Card } from "antd";
import { getMasteryLevelsByChild } from "../api/RecordKeeperAPI";
import { getAttendanceByChild } from "../api/AttendanceAPI";
import AttendanceTable from "./AttendanceTable";

export const renderMasteryTable = (subjectData, userRole) => (
  <Table
    columns={[
      {
        title: "Lesson",
        dataIndex: "lesson_name",
        key: "lesson_name",
        width: 400,
      },
      {
        title: "Level",
        dataIndex: "level",
        key: "level",
        width: 50,
      },
      ...(userRole !== "Guardian"
        ? [
            {
              title: "Notes",
              dataIndex: "notes",
              key: "notes",
              render: (notes) => (
                <div>
                  {notes.map((note, index) => (
                    <div key={index}>• {note.note}</div>
                  ))}
                </div>
              ),
            },
          ]
        : []),
    ]}
    dataSource={subjectData.map((item) => ({
      key: item.lesson_id,
      lesson_name: item.lesson_name,
      level: item.level,
      ...(userRole !== "Guardian" && { notes: item.notes }),
    }))}
    pagination={false}
    className="development-record-table"
  />
);

export const renderMasteryCards = (subjectData, userRole) => (
  <div className="mastery-card-list">
    {subjectData.map((item) => (
      <Card
        key={item.lesson_id}
        className="mastery-card"
        style={{ marginBottom: 8 }}
      >
        <p>{item.lesson_name}</p>
        <p>Level: {item.level}</p>
        {userRole !== "Guardian" && (
          <div>
            <ul>
              {item.notes.map((note, index) => (
                <li key={index}>{note.note}</li>
              ))}
            </ul>
          </div>
        )}
      </Card>
    ))}
  </div>
);

export const renderMasteryContent = (
  masteryLevels,
  selectedSubject,
  isMobile,
  userRole
) => {
  const renderSubjectContent = (subjectId, subjectData) => (
    <div key={subjectId} style={{ marginBottom: 24 }}>
      <h2>{subjectData[0].subject_name}</h2>
      {isMobile
        ? renderMasteryCards(subjectData, userRole)
        : renderMasteryTable(subjectData, userRole)}
    </div>
  );

  if (selectedSubject && masteryLevels[selectedSubject]) {
    return renderSubjectContent(
      selectedSubject,
      masteryLevels[selectedSubject]
    );
  }

  return Object.entries(masteryLevels).map(([subjectId, subjectData]) =>
    renderSubjectContent(subjectId, subjectData)
  );
};

export const renderAttendanceContent = (attendance, userRole) => {
  const renderSubjectContent = (attendance) => (
    <AttendanceTable attendanceData={attendance} userRole={userRole}/>
  );

  if (attendance) {
    return renderSubjectContent(attendance);
  }
};

export const fetchMasteryLevels = async (childId, setData) => {
  try {
    const masteryLevels = {};
    const subjects = [];
    let attendance = [];

    if (childId) {
      const [masteryData, attendanceData] = await Promise.all([
        getMasteryLevelsByChild(childId),
        getAttendanceByChild(childId),
      ]);

      attendance = attendanceData;

      masteryData.forEach((item) => {
        if (!masteryLevels[item.subject_id]) {
          masteryLevels[item.subject_id] = [];
          subjects.push({
            id: item.subject_id,
            name: item.subject_name,
          });
        }
        masteryLevels[item.subject_id].push(item);
      });
    }

    setData((prevData) => ({
      ...prevData,
      masteryLevels,
      subjects,
      attendance,
    }));
  } catch (error) {
    console.error("Error fetching mastery levels and attendance:", error);
    throw error;
  }
};
