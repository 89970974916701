import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Drawer, Button, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import GuardianEditForm from "./GuardianEditForm";
import CustomTable from "./CustomTable";
import { useAbility } from "../hooks/useAbility";
import { createGuardian, updateGuardian } from "../api/GuardianAPI";
import { getFullName } from "../helpers/childHelper";
import { formatPhoneNumber } from "../helpers/formatHelper";
import { createResponsiveFilterLayout } from "../helpers/responsiveFilterLayoutHelper";
import useIsMobile from "../hooks/useIsMobile";
import requestHandler from "../utils/requestHandler";

const { Search } = Input;

const NewButton = ({ onClick, ability }) => (
  <Button
    type="default"
    onClick={onClick}
    icon={<PlusOutlined />}
    disabled={!ability.can("create", "guardian")}
  />
);

const SearchBar = ({ value, onChange }) => (
  <Search
    placeholder="Search by name"
    allowClear
    value={value}
    onChange={onChange}
  />
);

const GuardianList = ({
  guardians,
  refreshGuardians,
  showNewButton = false,
  showSearchBar = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedGuardian, setSelectedGuardian] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const ability = useAbility();
  const isMobile = useIsMobile();

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredGuardians = guardians.filter((guardian) =>
    guardian.first_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleRowClick = (record) => {
    if (ability.can("update", "guardian")) {
      setSelectedGuardian(record);
      setIsDrawerOpen(true);
    }
  };

  const handleNewGuardian = () => {
    setSelectedGuardian({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = async (guardianData) => {
    if (selectedGuardian && selectedGuardian.id) {
      await requestHandler.execute(
        updateGuardian(selectedGuardian.guardian_id, guardianData),
        "Guardian updated.",
        "Failed to update guardian."
      );
    } else {
      await requestHandler.execute(
        createGuardian(guardianData),
        "New guardian created.",
        "Failed to create guardian."
      );
    }
    setIsDrawerOpen(false);
    refreshGuardians();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <Link to={`/guardians/${record.guardian_id}`}>
          {getFullName(record)}
        </Link>
      ),
    },
    {
      title: "Relationship",
      dataIndex: "relationship",
      key: "relationship",
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      render: (text, record) => (
        <div>
          <a href={`tel:${record.phone_number}`}>
            {formatPhoneNumber(record.phone_number)}
          </a>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  const filterComponents = [
    {
      component: <NewButton onClick={handleNewGuardian} ability={ability} />,
      show: showNewButton && ability.can("create", "guardian"),
      desktopSpan: 1,
      mobileSpan: 3,
    },
    {
      component: <SearchBar value={searchQuery} onChange={handleSearch} />,
      show: showSearchBar,
      desktopSpan: 23,
      mobileSpan: 21,
    },
  ];

  const { desktopLayout, mobileLayout } =
    createResponsiveFilterLayout(filterComponents);

  return (
    <div>
      {isMobile ? mobileLayout : desktopLayout}
      <CustomTable
        columns={columns}
        dataSource={filteredGuardians}
        currentPage={currentPage}
        onPageChange={(page) => setCurrentPage(page)}
        onRowClick={handleRowClick}
      />

      <Drawer
        width={350}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedGuardian(null);
        }}
        open={isDrawerOpen}
        closable={false}
      >
        {isDrawerOpen && (
          <GuardianEditForm
            guardian={selectedGuardian}
            onFinish={handleFormFinish}
            refreshGuardians={refreshGuardians}
            title={
              selectedGuardian && selectedGuardian.id
                ? "Edit Guardian"
                : "New Guardian"
            }
          />
        )}
      </Drawer>
    </div>
  );
};

export default GuardianList;
