import React, { useState, useEffect, useCallback } from "react";
import { Modal, Input, Button } from "antd";
import { getChildNotes, createChildNote } from "../api/ChildAPI";
import { formatDate } from "../helpers/dateTimeHelper";
import requestHandler from "../utils/requestHandler";

const ChildNotesModal = ({ child, visible, onClose }) => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchNotes = useCallback(async () => {
    setLoading(true);
    try {
      const response = await requestHandler.execute(
        getChildNotes(child.child_id),
        null, // No success message needed for fetching
        "Failed to fetch notes."
      );

      if (response) {
        const formattedNotes = response.map((note) => ({
          id: note.id,
          date: formatDate(note.date, "DD-MM-YY"),
          note: note.note,
          added_by: `${note.added_by_first_name}${note.added_by_last_name ? ` ${note.added_by_last_name}` : ""}`,
        }));
        setNotes(formattedNotes);
      }
    } finally {
      setLoading(false);
    }
  }, [child.child_id]);

  useEffect(() => {
    if (visible) {
      fetchNotes();
    }
  }, [visible, fetchNotes]);

  const handleAddNote = async () => {
    if (!newNote.trim()) {
      requestHandler.warning("Please enter a note.");
      return;
    }
    setIsSubmitting(true);
    try {
      await requestHandler.execute(
        createChildNote(child.child_id, newNote),
        "Note added.",
        "Failed to add note."
      );
      setNewNote("");
      fetchNotes();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={`Notes for ${child.first_name}`}
      open={visible}
      onCancel={onClose}
      footer={null}
      width={600}
      className="notes-modal"
    >
      <div className="add-note-section">
        <Input.TextArea
          rows={3}
          placeholder="Add a new note"
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
        />
        <Button
          type="primary"
          onClick={handleAddNote}
          loading={isSubmitting}
          style={{ marginTop: 8 }}
        >
          Add Note
        </Button>
      </div>

      <div className="notes-list">
        {loading ? (
          <p>Loading notes...</p>
        ) : (
          notes.map((note) => (
            <div key={note.id} className="note-item">
              <p className="note-text">{note.note}</p>
              <div className="note-meta">
                <span>{note.date}</span> &middot; <span>{note.added_by}</span>
              </div>
            </div>
          ))
        )}
      </div>
    </Modal>
  );
};

export default ChildNotesModal;
