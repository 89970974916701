import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { message } from "antd";
import DevelopmentRecordFilters from "../../components/record-keeper/DevelopmentRecordFilters";
import LoadingSpinner from "../../components/LoadingSpinner";
import IncompleteSetupMessage from "../../components/IncompleteSetupMessage";
import { useAuth } from "../../contexts/AuthContext";
import { getClassroomsWithChildren } from "../../api/SchoolAPI";
import { shouldShowClassroomSelector } from "../../helpers/teacherHelper";
import {
  renderMasteryContent,
  fetchMasteryLevels,
  renderAttendanceContent,
} from "../../helpers/developmentRecordHelper";
import { getIncompleteSetupMessage } from "../../helpers/setupHelper";
import "../../css/record-keeper/RecordKeeperPage.css";

const DevelopmentRecordPage = ({ isMobile }) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    classrooms: [],
    subjects: [],
    children: [],
    masteryLevels: {},
    attendance: [],
  });
  const [filters, setFilters] = useState({
    selectedClassroom: "",
    selectedSubject: "",
    selectedChildren: "",
    sortOption: "ageLowToHigh",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const classroomsWithChildren = await getClassroomsWithChildren();

        const formattedChildren = classroomsWithChildren.reduce(
          (acc, classroom) => {
            const childrenWithClassroom = classroom.children.map((child) => ({
              ...child,
              classroom_id: classroom.id,
            }));
            return [...acc, ...childrenWithClassroom];
          },
          []
        );

        setData((prevData) => ({
          ...prevData,
          classrooms: classroomsWithChildren.map((classroom) => ({
            id: classroom.id,
            name: classroom.name,
          })),
          children: formattedChildren,
        }));

        if (currentUser.classrooms && currentUser.classrooms.length > 0) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            selectedClassroom: currentUser.classrooms[0].id,
          }));
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [currentUser.classrooms]);

  useEffect(() => {
    if (filters.selectedChildren) {
      fetchMasteryLevels(filters.selectedChildren, setData).catch((error) => {
        message.error("Error fetching mastery levels and attendance");
        console.error("Error fetching mastery levels and attendance:", error);
      });
    }
  }, [filters.selectedChildren]);

  const handleChange = (field) => (value) => {
    setFilters((prev) => {
      const newFilters = { ...prev, [field]: value !== undefined ? value : "" };

      if (field === "selectedClassroom") {
        newFilters.selectedChildren = "";
        newFilters.selectedSubject = "";
        setData((prevData) => ({
          ...prevData,
          masteryLevels: {},
          attendance: [],
        }));
      }

      return newFilters;
    });
  };

  const incompleteMessage = getIncompleteSetupMessage(currentUser);

  return (
    <div className="development-record-page">
      <Helmet>
        <title>Development Record | Maidan</title>
      </Helmet>
      {incompleteMessage ? (
        <IncompleteSetupMessage
          title="Development Record"
          message={incompleteMessage}
        />
      ) : loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <h1 className="page-heading">Development Record</h1>
          <DevelopmentRecordFilters
            filters={filters}
            handleChange={handleChange}
            data={data}
            isMobile={isMobile}
            showClassroomFilter={shouldShowClassroomSelector(currentUser)}
            showSubjectFilter={true}
            showChildrenFilter={true}
          />
          <div>
            {renderMasteryContent(
              data.masteryLevels,
              filters.selectedSubject,
              isMobile,
              "Teacher"
            )}
          </div>
          {data.attendance.length > 0 && (
            <>
              <h2>Attendance Log</h2>
              <div>{renderAttendanceContent(data.attendance, "Teacher")}</div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DevelopmentRecordPage;
