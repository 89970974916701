import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { getCollections } from "../../api/library/CollectionAPI"; // Import the API call for fetching collections

const { Option } = Select;

const CollectionsDropdown = ({ value = [], onChange }) => {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const fetchCollections = async () => {
      setLoading(true);
      try {
        const collectionsList = await getCollections(); // Fetch collections from the API
        setCollections(collectionsList);
      } catch (error) {
        console.error("Error fetching collections:", error);
      }
      setLoading(false);
    };

    fetchCollections();
  }, []);

  const handleSearch = (searchText) => {
    setInputValue(searchText);
  };

  const handleSelect = (selectedValue) => {
    const collectionExists = collections.some((collection) => collection.id === selectedValue);

    if (!collectionExists) {
      const newCollection = { id: selectedValue, name: selectedValue };
      setCollections((prevCollections) => [...prevCollections, newCollection]);
    }

    onChange([...value, selectedValue]); // Spread the current value array and add the new value
  };

  return (
    <Select
      mode="multiple" // Allow multiple selections
      value={value}
      onChange={onChange}
      onSearch={handleSearch}
      onSelect={handleSelect}
      showSearch
      placeholder="Select Collections"
      loading={loading}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
    >
      {collections.map((collection) => (
        <Option key={collection.name} value={collection.name}>
          {collection.name}
        </Option>
      ))}
      {/* Allow user to add a new option dynamically */}
      {inputValue && !collections.some((collection) => collection.name === inputValue) && (
        <Option key={inputValue} value={inputValue}>
          {`Add "${inputValue}"`}
        </Option>
      )}
    </Select>
  );
};

export default CollectionsDropdown;
