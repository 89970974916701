import React, { useEffect, useState } from "react";
import { Table, Spin, Alert, Tag } from "antd";
import { getPresentations } from "../../api/EmployeeAPI";

const TeacherPresentationsList = ({ membershipId, classroomId = null }) => {
  const [presentations, setPresentations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPresentations = async () => {
      try {
        setLoading(true);
        const response = await getPresentations(membershipId, classroomId);
        setPresentations(response);
      } catch (err) {
        setError("Failed to load presentations data.");
      } finally {
        setLoading(false);
      }
    };

    fetchPresentations();
  }, [membershipId, classroomId]);

  const columns = [
    {
      title: "Date",
      dataIndex: "presentation_date",
      key: "presentation_date",
      render: (date) => <div>{new Date(date).toLocaleDateString()}</div>,
    },
    {
      title: "Lesson",
      dataIndex: "lesson",
      key: "lesson",
      render: (lesson) => (
        <div>
          <strong>{lesson.name}</strong>
          <div style={{ color: "grey" }}>
            {lesson.parent_name ? `Parent: ${lesson.parent_name}` : "No Parent"}
          </div>
          <div style={{ color: "grey" }}>Subject: {lesson.subject_name}</div>
        </div>
      ),
    },
    {
      title: "Children",
      dataIndex: "children",
      key: "children",
      render: (children) =>
        Array.isArray(children) && children.length > 0 ? (
          children.map((child) => (
            <Tag key={child.id}>
              {child.first_name} {child.last_name}
            </Tag>
          ))
        ) : (
          <span>No children</span>
        ),
    },
  ];

  return (
    <div>
      {loading ? (
        <Spin tip="Loading..." />
      ) : error ? (
        <Alert message={error} type="error" showIcon />
      ) : presentations.length === 0 ? (
        <Alert
          message="No presentations found for this teacher."
          type="info"
          showIcon
        />
      ) : (
        <Table
          columns={columns}
          dataSource={presentations.map((presentation, index) => ({
            ...presentation,
            key: `${presentation.presentation_date}-${presentation.lesson.id}-${index}`,
          }))}
          pagination={{ pageSize: 10 }}
          bordered
        />
      )}
    </div>
  );
};

export default TeacherPresentationsList;
