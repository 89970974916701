import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { Card, Typography, Row, Col } from "antd";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { getChildById } from "../api/ChildAPI";
import { useAbility } from "../hooks/useAbility";
import { formatPhoneNumber } from "../helpers/formatHelper";
import LoadingSpinner from "../components/LoadingSpinner";
import MembershipInfo from "../components/MembershipInfo";
import ManageClassroomsModal from "../components/ManageClassroomsModal";
import ManageGuardiansModal from "../components/ManageGuardiansModal";
import {
  mapChildToClassroom,
  unmapChildFromClassroom,
  mapGuardianToChild,
  unmapGuardianFromChild,
} from "../api/SchoolAPI";
import "../css/ChildPage.css";

const { Text } = Typography;

const ChildPage = () => {
  const { childId } = useParams();
  const [child, setChild] = useState(null);
  const [isClassroomModalOpen, setIsClassroomModalOpen] = useState(false);
  const [isGuardianModalOpen, setIsGuardianModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const ability = useAbility();

  const fetchChild = useCallback(async () => {
    try {
      const data = await getChildById(childId);
      setChild(data);
    } catch (error) {
      console.error("Error fetching child:", error);
    }
  }, [childId]);

  useEffect(() => {
    fetchChild();
  }, [fetchChild]);

  const handleManageClassrooms = (mode) => {
    setModalMode(mode);
    setIsClassroomModalOpen(true);
  };

  const handleManageGuardians = (mode) => {
    setModalMode(mode);
    setIsGuardianModalOpen(true);
  };

  const handleAddClassrooms = async (classroomIds) => {
    try {
      for (const classroomId of classroomIds) {
        await mapChildToClassroom(classroomId, childId);
      }
      setIsClassroomModalOpen(false);
      fetchChild();
    } catch (error) {
      console.error("Error adding classrooms to child:", error);
    }
  };

  const handleRemoveClassrooms = async (classroomIds) => {
    try {
      for (const classroomId of classroomIds) {
        await unmapChildFromClassroom(classroomId, childId);
      }
      setIsClassroomModalOpen(false);
      fetchChild();
    } catch (error) {
      console.error("Error removing classrooms from child:", error);
    }
  };

  const handleAddGuardians = async (guardianData) => {
    try {
      for (const { guardianId, relationship } of guardianData) {
        await mapGuardianToChild(childId, guardianId, relationship);
      }
      setIsGuardianModalOpen(false);
      fetchChild();
    } catch (error) {
      console.error("Error adding guardians to child:", error);
    }
  };

  const handleRemoveGuardians = async (guardianData) => {
    try {
      for (const { guardianId } of guardianData) {
        await unmapGuardianFromChild(childId, guardianId);
      }
      setIsGuardianModalOpen(false);
      fetchChild();
    } catch (error) {
      console.error("Error removing guardians from child:", error);
    }
  };

  const menuItems = [
    {
      key: "addClassroom",
      label: "Add Classroom",
      onClick: () => handleManageClassrooms("add"),
    },
    {
      key: "removeClassroom",
      label: "Remove Classroom",
      onClick: () => handleManageClassrooms("remove"),
    },
    {
      key: "addGuardian",
      label: "Add Guardian",
      onClick: () => handleManageGuardians("add"),
    },
    {
      key: "removeGuardian",
      label: "Remove Guardian",
      onClick: () => handleManageGuardians("remove"),
    },
  ];

  if (!child) return <LoadingSpinner />;

  return (
    <div className="child-page">
      <Helmet>
        <title>{`${child.first_name} | Maidan`}</title>
      </Helmet>

      <MembershipInfo
        membership={child}
        id={child.sid}
        classrooms={child.classrooms}
        menuItems={
          ability.can("add", "classroom") && ability.can("add", "guardian")
            ? menuItems
            : null
        }
      />

      <Row gutter={[16, 16]}>
        {child.guardians.map((guardian) => (
          <Col xs={24} sm={12} key={guardian.id}>
            <Card className="guardian-card" title={guardian.relationship}>
              <div className="guardian-content">
                <img
                  src={guardian.profile_photo_url || "/icons/profile-icon.png"}
                  alt={`${guardian.first_name} ${guardian.last_name}`}
                  className="guardian-image"
                />
                <div className="guardian-info">
                  <Text strong className="guardian-name">
                    {`${guardian.first_name} ${guardian.last_name}`}
                  </Text>
                  <div className="guardian-contact">
                    <div>
                      <PhoneOutlined />{" "}
                      <a href={`tel:${guardian.phone_number}`}>
                        {formatPhoneNumber(guardian.phone_number) ||
                          "Not available"}
                      </a>
                    </div>
                    <div>
                      <MailOutlined /> {guardian.email || "Not available"}
                    </div>
                  </div>
                  <div className="guardian-occupation">
                    <Text strong>Occupation:</Text>{" "}
                    {guardian.occupation || "Not available"}
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>

      <ManageClassroomsModal
        childId={childId}
        isOpen={isClassroomModalOpen}
        onClose={() => setIsClassroomModalOpen(false)}
        onAdd={
          modalMode === "add" ? handleAddClassrooms : handleRemoveClassrooms
        }
        mode={modalMode}
        existingClassrooms={child.classrooms}
      />

      <ManageGuardiansModal
        childId={childId}
        isOpen={isGuardianModalOpen}
        onClose={() => setIsGuardianModalOpen(false)}
        onAdd={modalMode === "add" ? handleAddGuardians : handleRemoveGuardians}
        mode={modalMode}
        existingGuardians={child.guardians}
      />
    </div>
  );
};

export default ChildPage;
